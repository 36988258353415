import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { getStoreData } from '../redux/StartupRedux'
import { getFingerPrint } from '../redux/SearchRedux'

const storeData = ({ startup }) => getStoreData(startup)
const fingerprintData = ({ search }) => getFingerPrint(search)

export function * searchProducts(api, { param }) {
  const store = yield select(storeData)
  const fingerprint = yield select(fingerprintData)

  const response = yield call(api.searchProducts, {
    store: {
      storeId: store.store_id,
      name: store.name,
      url: store.url
    },
    tenant: store ? store.tenant || '' : '',
    fingerprint: fingerprint,
    query: param.query
  })
  
  const { ok, data } = response

  if (ok) {
    const { products, fingerprint } = data

    yield put(Creators.productSearchSuccess(products, fingerprint))
  } else {
    const { error } = data

    yield put(Creators.productSearchFailure(error))
  }
}