import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.masterApi
})

const authApi = create({
  baseURL: Config.authApi
})



// Configuration
const setHeaders = (opts) => api.setHeaders(opts)
const setURL = (url) => api.setBaseURL(url)

const setAuthHeaders = (opts) => authApi.setHeaders(opts)

// Methods
const startup = () => authApi.get('v1/store/startup')
const login = ({ email, password }) => authApi.post('/v1/customer/login', { email, password })
const renewToken = () => authApi.get('/v1/customer/renew')
const register = ({ firstname, lastname, telephone, email, password }) => authApi.post('/v1/customer/register', { firstname, lastname, telephone, email, password })
const getCategories = () => api.get('/v1/category')
const getDeliverySchedule = () => api.get('/v1/delivery/schedule')
const getBanners = () => api.get('v1/banner')
const getFeaturedProducts = () => api.get('v1/product/feature')
const getAboutUs = () => api.get('v1/about-us')
const startSession = ({ payment_option_id }) => api.post('/v1/gateway/session', { payment_option_id })
const getProductBySlug = ({ slug, fingerprint, customer_id = null }) => api.post(`v1/product/${slug}`, { fingerprint, customer_id })

export default {
  setHeaders,
  setURL,
  setAuthHeaders,

  startup,
  login,
  renewToken,
  register,
  getCategories,
  getDeliverySchedule,
  getBanners,
  getFeaturedProducts,
  getAboutUs,
  startSession,
  getProductBySlug
}
