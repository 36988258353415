/* eslint-disable no-useless-escape */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import LaddaButton, { XL } from '@zumper/react-ladda'
import { RegisterContainer, RegisterColumn, Button, Error, Heading, InputMask } from './styles'
import Input from '@components/Input'
import FacebookIcon from '@components/Icons/Facebook'
import GmailIcon from '@components/Icons/Gmail'
import { isLogged } from '../../redux/LoginRedux'
import { Creators } from '../../actions'
import ErrorCode from '../../helpers/ErrorCodes'

class Register extends Component {
  constructor(props) {
    super(props)

    this.state = {
      facebookEnabled: false,
      googleEnabled: false,
      currentAction: 'register',
      currentFocus: 'register',
      canRegister: false,
      loading: false,
      inputs: {
        registerFirstname: '',
        registerLastname: '',
        registerPhone: '',
        registerEmail: ''
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { fetching } = newProps
    const { loading } = this.state

    if (!fetching && loading) {
      this.setState({
        loading: false
      })
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }), () => {
      // Validate register fields
      this.validateRegisterFields()
    })
  }

  onFocusChange = ({ target: { name } }) => {
    const { currentFocus } = this.state
    const focus = name.indexOf('register') >= 0 ? 'register' : 'login'
    
    if (currentFocus !== focus) {
      this.setState({
        currentFocus: focus
      })
    }
  }

  validateRegisterFields = () => {
    const { canRegister, inputs } = this.state
    const { registerFirstname, registerLastname, registerPhone, registerEmail } = inputs


    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const phoneNumber = registerPhone.match(/\d+/g) ? registerPhone.match(/\d+/g) : ''

    let bValid = false
    if (registerFirstname.length > 0 && registerLastname.length > 0 && phoneNumber.length > 2 && registerEmail.length > 0) {
      bValid = emailRegex.test(registerEmail.toLowerCase())
    }

    if (canRegister !== bValid) {
      this.setState({
        canRegister: bValid
      })
    }
  }

  _handleRegister = () => {
    const { attempRegister } = this.props
    const { inputs } = this.state
    const { registerFirstname, registerLastname, registerPhone, registerEmail } = inputs

    // Generate random password
    const length = 8
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let randomPassword = ''

    for (var i = 0, n = charset.length; i < length; i++) {
      randomPassword += charset.charAt(Math.floor(Math.random() * n))
    }

    this.setState({
      currentAction: 'register',
      canRegister: false,
      loading: true
    }, () => {
      attempRegister({
        firstname: registerFirstname,
        lastname: registerLastname,
        telephone: registerPhone,
        email: registerEmail.toLowerCase(),
        password: randomPassword
      })
    })
  }

  _renderRegister = () => {
    const { error } = this.props
    const { inputs, currentAction, currentFocus, canRegister, loading } = this.state
    const { registerFirstname, registerLastname, registerPhone, registerEmail } = inputs

    return (
      <div>
        {error && currentAction === 'register' ? <Error className="animated slideInDown">{ErrorCode(error)}</Error> : null}

        <Input type="text" autoComplete="off" name="registerFirstname" value={registerFirstname} placeholder="Nome" onChange={this.onChangeField} onFocus={this.onFocusChange} style={{ marginBottom: 5 }} height={10} fontSize={16} />
        <Input type="text" autoComplete="off" name="registerLastname" value={registerLastname} placeholder="Sobrenome" onChange={this.onChangeField} onFocus={this.onFocusChange} style={{ marginBottom: 5 }} height={10} fontSize={16} />

        <InputMask
          id="registerPhone"
          name="registerPhone"
          value={registerPhone}
          placeholder="Telefone"
          autoComplete="off"
          mask="(99) 99999-9999"
          height={10}
          fontSize={16}
          onChange={this.onChangeField}
          onFocus={this.onFocusChange}
          style={{ marginBottom: 20 }}
        />

        <Input type="email" autoComplete="off" name="registerEmail" value={registerEmail} placeholder="Digite seu E-mail" onChange={this.onChangeField} onFocus={this.onFocusChange} style={{ marginBottom: 5 }} height={10} fontSize={16} />

        <LaddaButton
          type={currentFocus === 'register' ? 'submit' : 'button'}
          className="btn-ladda-primary"
          loading={loading && currentAction === 'register'}
          size={XL}
          spinnerSize={30}
          spinnerColor="#ddd"
          spinnerLines={12}
          onClick={this._handleRegister}
          disabled={!canRegister}
        >
          Registrar
        </LaddaButton>
      </div>
    )
  }

  render() {
    const { loggedIn } = this.props
    const { facebookEnabled, googleEnabled } = this.state
    const isMobile  = window.innerWidth < 768

    return (
      <RegisterContainer mobile={isMobile}>
        <RegisterColumn style={{width: isMobile ? '70%' : '30%'}}>
          <Heading>Cadastre-se para receber ofertas</Heading>
          {
            facebookEnabled ? (
              <Button>
                <FacebookIcon />
                Use o Facebook
              </Button>
            ) : null
          }
          {
            googleEnabled ? (
              <Button gmail>
                <GmailIcon />
                Use o Google
              </Button>
            ) : null
          }
          {this._renderRegister()}
        </RegisterColumn>
        
        { 
          loggedIn ? <Redirect from="/registro" to="/produtos" /> : null
        }
      </RegisterContainer>
    )
  }
}

const mapStateToProps = ({ login }) => ({
  fetching: login.fetching,
  error: login.error,
  loggedIn: isLogged(login)
})

const mapDisptachToProps = dispatch => ({
  attempRegister: ({ firstname, lastname, telephone, email, password }) => dispatch(Creators.registerRequest(firstname, lastname, telephone, email, password))
})

export default connect(mapStateToProps, mapDisptachToProps)(Register)
