import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loggedToken } from '../redux/LoginRedux'
import { loadedStore } from '../redux/StartupRedux'

const userToken = ({ startup }) => loggedToken(startup)
const storeToken = ({ startup }) => loadedStore(startup)

export function * login(api, { email, password }) {
  const response = yield call(api.login, { email, password })
  const { ok, data, problem } = response

  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.loginSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.loginFailure(error))
  }
}

export function * refreshToken(api) {
  const token = yield select(userToken)
  const store = yield select(storeToken)
  api.setAuthHeaders({'Authorization': `Bearer ${token}`})
  api.setHeaders({'Authorization': `Bearer ${store}`})

  const response = yield call(api.renewToken)
  const { ok, data, problem } = response

  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.refreshTokenSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.refreshTokenFailure(error))
  }
}

export function * register(api, { firstname, lastname, telephone, email, password }) {
  const response = yield call(api.register, { firstname, lastname, telephone, email, password })
  const { ok, data, problem } = response

  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.registerSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.registerFailure(error))
  }
}
