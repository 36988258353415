import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'

const storeToken = ({ startup }) => loadedStore(startup)

export function * getBanners(api) {
  const token = yield select(storeToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})
  
  const response = yield call(api.getBanners)
  const { ok, data } = response

  if (ok) {
    const { banners } = data

    yield put(Creators.bannerListSuccess(banners))
  } else {
    const { error } = data

    yield put(Creators.bannerListFailure(error))
  }
}
