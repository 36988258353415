import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { HeaderContainer, InfoContainer, UsernameContainer, Dropdown, DropdownItem, WhatsAppButton } from './styles'
import { Row, Col } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import Banner from '@components/Banner'
import Logo from '@components/Logo'
import NavBar from '@components/NavBar'
import Button from '@components/Button'
import { isLogged } from '../../redux/LoginRedux'
import { Creators } from '../../actions'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'
import Capitalize from '../../helpers/Capitalize'
import './index.css'
import GAEvents from '../../helpers/GAEvents'

// Google Analytics
import ReactGA from 'react-ga';

//Google Tag Manager
import TagManager from 'react-gtm-module'

// Facebook Pixel
import ReactPixel from 'react-facebook-pixel'

// Hotjar
import { hotjar } from 'react-hotjar'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      logout: false,
      metaTitle: null,
      metaDescription: null
    }
  }

  componentDidMount() {
    const { attemptGetBanners } = this.props

    attemptGetBanners()
  }

  componentWillReceiveProps(newProps) {
    const { location, fetching, store, productFetching, productDetail } = newProps
    const { state, pathname } = location

    // Copy current state
    const newState = Object.assign({}, this.state)

    if (!fetching && store) {
      const { settings } = store
      const { config_meta_title, config_meta_description } = settings

      // Update page title
      document.title = config_meta_title

      newState.metaTitle = config_meta_title
      newState.metaDescription = config_meta_description
    }

    // Pages metas handle
    if (pathname === '/sobre' || pathname === '/contato') {
      const { settings } = store
      const { config_store_city, config_name } = settings

      switch(pathname) {
        case '/sobre':
          newState.metaTitle = `Saiba mais sobre a ${config_name} - ${config_store_city}`
          newState.metaDescription = `Conheça mais sobre a ${config_name}! Farmácia em ${config_store_city} com o melhor atendimento, medicamentos e linha completa de perfumaria!`
          break
        case '/contato':
          newState.metaTitle = `Entre em contato com ${config_name} aqui!`
          newState.metaDescription = `Clique e veja telefone e todos os contatos da ${config_name}! O atendimento mais rápido e prático de farmácia em ${config_store_city}`
          break
        default:
          break
      }
    }

    // Products metas handle
    if (state && state.metas) {
      const { cachedProduct, category, subcategory } = state
      const metaProduct = cachedProduct || productDetail

      if (!productFetching && metaProduct) {
        const { model, name, categories } = metaProduct
        const { settings } = store
        const { config_store_city, config_name } = settings

        // Update meta title
        newState.metaTitle = `${Capitalize(model)} ${Capitalize(name)} - ${Capitalize(config_store_city)}`

        // Update meta description
        if (categories && categories.length > 0 && categories[0].name.toLowerCase() !== 'outros') {
          let parentCategory = undefined
          parentCategory = categories.reverse()[0]

          newState.metaDescription = `${model} em ${config_store_city} você encontra aqui no site da ${config_name}, além de toda linha completa de ${parentCategory.name}!`
        } else {
          newState.metaDescription = `${model} em ${config_store_city} você encontra aqui no site da ${config_name}. São milhares de produtos e ofertas!`
        }
      }

      // Maybe we need to set category metas :P
      if (!productFetching && !metaProduct) {
        if (category && !subcategory) {
          const { meta_title, meta_description } = category
         
          newState.metaTitle = meta_title
          newState.metaDescription = meta_description
        }

        if (subcategory) {
          const { meta_title, meta_description } = subcategory
         
          newState.metaTitle = meta_title
          newState.metaDescription = meta_description
        }
      }

      // Update page title
      document.title = newState.metaTitle
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  toggleDropdown = () => {
    this.setState(state => ({
      ...state,
      dropdownOpen: !state.dropdownOpen
    }))
  }

  onDropdownClick = (logout = false) => {
    const { attemptLogout } = this.props

    this.setState({
      dropdownOpen: false,
      logout
    }, () => {
      if (logout) {
        attemptLogout()
      }
    })
  }

  _titleStyles = (text) => {
    const len = text.length > 30 ? 26 : 30
    const perc = text.length / 100
    const size = (len - (len * perc)).toFixed(0)

    return {
      fontSize: Number(size)
    }
  }

  render() {
    const { loggedIn, user, store, banners, location } = this.props
    const { settings } = store
    const { config_name, config_address, config_store_city, config_store_number, config_navbar_color, config_logo, config_analytics_id, config_pixel_id, config_hotjar_id,  config_whatsapp_phone, config_whatsapp_button, config_google_tag_manager_id } = settings
    const { dropdownOpen, logout, metaTitle, metaDescription } = this.state

    const storeAddress = `${config_address}, ${config_store_number ? config_store_number + ',' : ''} ${config_store_city || ''}`
    const storeLogo = config_logo && config_logo.length > 0 ? `${Config.s3Bucket}${config_logo}` : NoImage

    // Responsive title text
    const titleStyles = this._titleStyles(config_name)

    if (config_analytics_id && config_analytics_id.length > 0){
      ReactGA.initialize(config_analytics_id)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    if (config_google_tag_manager_id && config_google_tag_manager_id.length > 0){
      TagManager.initialize({ gtmId: config_google_tag_manager_id })
    }

    if (config_pixel_id && config_pixel_id.length > 0){
      ReactPixel.init(config_pixel_id)
      ReactPixel.pageView()
    }

    if (config_hotjar_id && config_hotjar_id.length > 0){
      hotjar.initialize(config_hotjar_id, 6)
    }

    let bannerImages = []
    if (banners) {
      bannerImages = banners.map(p => {
        const { image } = p

        return `${Config.s3Bucket}${image}`
      })
    }

    return (
      <HeaderContainer>
        <Helmet>
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        </Helmet>

        <Banner images={bannerImages} defaultColor={config_navbar_color} />
        <a href="/">
          <Logo image={storeLogo} alt={config_name} />
        </a>
        <InfoContainer>
          <Row>
            <Col xs={8}>
              <h1 style={{cursor: 'pointer', ...titleStyles}} onClick={() => window.location = '/'}>{config_name}</h1>
              <h4 style={{cursor: 'pointer', lineHeight: '1em', marginTop: 10}} onClick={() => window.open(`https://www.google.com/maps/place/${storeAddress}`, '_blank') }>{storeAddress}</h4>
            </Col>
            <Col xs={4}>
              {
                loggedIn ? (
                  <UsernameContainer>
                    <h3>Olá, {user.firstname}</h3>
                    <Button 
                    color="secondary"
                    onClick={this.toggleDropdown}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 40,
                      width: 40,
                      fontSize: 22,
                      borderRadius: 3,
                      padding: 0,
                      marginLeft: 10,
                      borderColor: '#fff',
                      color: '#fff'
                    }}
                    >
                      <FontAwesomeIcon icon={Icons.faBars} />
                    </Button>
                    <Dropdown className={`animated ${dropdownOpen ? 'flipInX' : 'flipOutX'}`} width={200} open={dropdownOpen}>
                      <DropdownItem onClick={() => this.onDropdownClick(true)}>Sair</DropdownItem>
                    </Dropdown>
                  </UsernameContainer>
                ) : (
                  <Link to="/registro">
                    <Button style={{height: 40, padding: '0 10px', marginLeft: 25}}>Receba ofertas</Button>
                  </Link>
                )
              }
            </Col>
          </Row>
        </InfoContainer>
        <NavBar/>

        {logout ? <Redirect to="/produtos" /> : null}
        {location && location.pathname === '/' ? <Redirect exact from="/" to="/produtos" /> : null}

        {
          config_whatsapp_button && config_whatsapp_phone && config_whatsapp_phone.length > 0 ? (
            <WhatsAppButton href={`https://api.whatsapp.com/send?phone=55${config_whatsapp_phone.replace(/\D+/g, '')}&text=Olá, estava no site e preciso de auxílio`} target="_blank" onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp' }) }}>
              <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="2x" />
            </WhatsAppButton>
          ) : null
        }
      </HeaderContainer>
    )
  }
}

const mapStateToProps = ({ login, startup, banner, product }) => ({
  fetching: startup.fetching,
  store: startup.store,
  user: login.user,
  banners: banner.banners,
  productFetching: product.fetching,
  productDetail: product.product,
  loggedIn: isLogged(login)
})

const mapPropsToDisptach = dispatch => ({
  attemptLogout: () => dispatch(Creators.logoutRequest()),
  attemptGetBanners: () => dispatch(Creators.bannerListRequest())
})

export default withRouter(connect(mapStateToProps, mapPropsToDisptach)(Header))