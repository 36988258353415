import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  cartSetProductRequest: ['products'],
  cartListProductRequest: null,

  startupRequest: null,
  startupSuccess: ['store', 'accessToken'],
  startupFailure: ['error'],

  productListRequest: ['category_id', 'params'],
  productListSuccess: ['data'],
  productListFailure: ['error'],

  productSearchRequest: ['param'],
  productSearchSuccess: ['products', 'fingerprint'],
  productSearchFailure: ['error'],

  productDetailRequest: ['slug'],
  productDetailSuccess: ['product'],
  productDetailFailure: ['error'],

  featuredProductListRequest: null,
  featuredProductListSuccess: ['products'],
  featuredProductListFailure: ['error'],

  loginRequest: ['email', 'password'],
  loginSuccess: ['user'],
  loginFailure: ['error'],

  registerRequest: ['firstname', 'lastname', 'telephone', 'email', 'password'],
  registerSuccess: ['user'],
  registerFailure: ['error'],

  refreshTokenRequest: null,
  refreshTokenSuccess: ['user'],
  refreshTokenFailure: ['error'],

  logoutRequest: null,
  setStoreRedirect: ['status'],

  categoryListRequest: null,
  categoryListSuccess: ['categories'],
  categoryListFailure: ['error'],

  deliveryListRequest: ['neighborhood_id'],
  deliveryListSuccess: ['deliveries'],
  deliveryListFailure: ['error'],

  deliveryScheduleRequest: null,
  deliveryScheduleSuccess: ['schedule'],
  deliveryScheduleFailure: ['error'],

  bannerListRequest: null,
  bannerListSuccess: ['banners'],
  bannerListFailure: ['error'],

  aboutUsRequest: null,
  aboutUsSuccess: ['content'],
  aboutUsFailure: ['error']
})
