import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Body } from './styles.js'
import DrugstoreInfo from '@components/DrugstoreInfo'
import PulsingCircle from '@components/Icons/PulsingCircle'
import { Grid, Col, Row, } from 'react-flexbox-grid'
import { Creators } from '../../actions'
import { format, getHours, getMinutes, setMinutes, setHours, isWithinInterval, isBefore, isAfter, parseISO } from 'date-fns'
import './index.css'

const WEEK_DAY = [
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb',
  'Dom',
  'Fer'
]

class BodyContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingDeliveries: false,
      storeOpen: true,
      deliverySchedule: []
    }
  }

  componentDidMount() {
    const { attemptGetDeliverySchedule } = this.props

    attemptGetDeliverySchedule()
  }

  componentWillReceiveProps(newProps) {
    const { _delivery } = newProps
    let newState = Object.assign({}, this.state)

    // Delivery schedule loaded
    if (!_delivery.fetching && _delivery.schedule !== null) {
      const { schedule } = _delivery
      const now = new Date()
      
      let storeOpen = false

      newState.deliverySchedule = schedule

      schedule.forEach(p => {
        if (Number(p.day) === Number(format(new Date(),'i'))) {

          const startMinutes = getMinutes(new Date(p.start))
          const startHour = getHours(new Date(p.start))

          const endMinutes = getMinutes(new Date(p.end))
          const endHour = getHours(new Date(p.end))

          const start = setMinutes(setHours(now,startHour),startMinutes)
          const end = setMinutes(setHours(now,endHour),endMinutes)

          if (isWithinInterval(now,{ start, end })) {
            storeOpen = true
          }
        }
      })

      if (storeOpen !== newState.storeOpen) {
        newState.storeOpen= storeOpen
      }
    }

    // Update state if has changed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  _renderScheduleDropdownContent = () => {
    const { deliverySchedule } = this.state
    
    return (
      <Grid className="schedule">
        {
          deliverySchedule.map((schedule, index) => {
            const { day, start, end } = schedule
            const exactDay = Number(day - 1)

            let startStr = `${format(new Date(start),'kk')}`
            let endStr = `${format(new Date(end),'kk')}`
          
            if(getMinutes(new Date(start)) > 0){
              startStr += `:${format(new Date(start),'mm')}`
            }else{
              startStr += ' h'
            }

            if(getMinutes(new Date(end)) > 0){
              endStr += `:${format(new Date(end),'mm')}`
            }else{
              endStr += ' h'
            }
           
            return (
              <Row key={index}>
                <Col xs={3} className="primary-text">
                  <strong>{WEEK_DAY[exactDay]}.</strong>
                </Col>
                <Col xs={8} xsOffset={1} className="secondary-text" style={{fontSize: 14}}> {`${startStr} às ${endStr}`}</Col>
              </Row>
            )
          })
        }
      </Grid>
    )
  }

  _renderBodyHeader = () => {
    const { filterComponent } = this.props
    const { deliverySchedule, storeOpen } = this.state

    let scheduleTime = ''
    deliverySchedule.forEach(schedule => {
      if (Number(schedule.day) === Number(format(new Date(),'i'))) {
        const now = format(Date.now(), 'pp')
        const timeStart = format(new Date(schedule.start), 'pp')
        const timeEnd = format(new Date(schedule.end), 'pp')
        
        if (!isBefore(parseISO(timeStart),parseISO(now)) && !isAfter(parseISO(timeEnd),parseISO(now))) {

            let startStr = `${format(new Date(schedule.start),'kk')}`
            let endStr = `${format(new Date(schedule.end),'kk')}`
          
            if(getMinutes(parseISO(schedule.start)) > 0){
              startStr += `:${format(parseISO(schedule.start),'mm')}`

            }else {
              startStr += ' h'
            }

            if(getMinutes(parseISO(schedule.end)) > 0){
              endStr += `:${format(parseISO(schedule.end),'mm')}`

            }else {
              endStr += ' h'
            }
          scheduleTime = `Hoje: ${startStr} às ${endStr}`
        }
      }
    })

    return (
      <Body>
        {
          filterComponent
        }

        <DrugstoreInfo
          icon={<PulsingCircle open={storeOpen} />}
          primaryText="Horários de funcionamento"
          secondaryText={scheduleTime}
          dropdown
        >
          {this._renderScheduleDropdownContent()}
        </DrugstoreInfo>
      </Body>
    )
  }

  render() {
    const { children } = this.props
  
    return (
      <React.Fragment>
        {
          this._renderBodyHeader()
        }

        <Body column>
          {children}
        </Body>
        <ToastContainer />
      </React.Fragment >
    )
  }
}

const mapStateToProps = ({ delivery, payment, address }) => ({
  _delivery: delivery
})

const mapDispatchToProps = dispatch => ({
  attemptGetDeliverySchedule: () => dispatch(Creators.deliveryScheduleRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer)
