import { call, put } from 'redux-saga/effects'
import { Creators } from '../actions'
const { REACT_APP_LOCAL_ORIGIN } = process.env

export function * startup(api) {
  if (process.env.NODE_ENV === 'development') {
    api.setAuthHeaders({
      'x-origin': REACT_APP_LOCAL_ORIGIN
    })
  }

  const response = yield call(api.startup)
  const { ok, data } = response

  if (ok) {
    const { store, accessToken } = data

    yield put(Creators.startupSuccess(store, accessToken))
  } else {
    const { error } = data

    yield put(Creators.startupFailure(error))
  }
}
