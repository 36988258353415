import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  email: null,
  password: null,
  user: null,
  storeRedirect: true
})

export const loginRequest = (state = INITIAL_STATE, { email, password }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    email,
    password,
    user: null
  })

export const loginSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    email: null,
    password: null,
    user: user
  })

export const loginFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: error,
    email: null,
    password: null,
    user: null
  })

export const refreshTokenRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false
  })

export const refreshTokenSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user
  })

export const refreshTokenFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    user: null
  })

export const logoutRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    email: null,
    password: null,
    user: null
  })

export const registerRequest = (state = INITIAL_STATE, { firstname, lastname, telephone, email, password }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    user: null
  })

export const registerSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user: user
  })

export const registerFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: error,
    user: null
  })

export const setStoreRedirect = (state = INITIAL_STATE, { status }) =>
  Object.assign({}, state, {
    storeRedirect: status
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,

  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILURE]: registerFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,

  [Types.SET_STORE_REDIRECT]: setStoreRedirect
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer

export const isLogged = loginState =>
  loginState.user !== null

export const loggedToken = loginState => loginState.user.accessToken || null